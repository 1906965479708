.about {
  flex-direction: column;
  margin-top: 2em;
}

.about-pic {
  width: 40%;
  margin: auto;
  margin-bottom: 1.25rem;
  border-radius: 0.625rem;
	box-shadow: 0 1.5rem 3rem -0.75rem rgb(0 0 0 / 0.25);
}

.floating { 
	animation-name: floating;
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes floating {
	0% { transform: translate(0, 0rem); }
	50% { transform: translate(0, 0.625rem); }
	100% { transform: translate(0, -0rem); } 
}


.about-name {
  margin: auto;
  background-color: transparent;
}

.first-name {
  color: var(--clr-primary);
}

.about-role {
  color: var(--clr-secondary);
}

.about-desc {
  font-size: 1.2rem;
  max-width: 600px;
  margin: auto;
  margin-top: 1.2em;
}

.about-contact {
  margin: auto;
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }

  .about-pic {
    width: 80%;
  }

  .about-name {
    margin-top: 0.5em;
  }

}

.button {
  border-radius: 5px;
}
.project {
  padding: 1em;
  margin: 0 auto;
  text-align: center;
  /* box-shadow: var(--shadow); */
  border: 2px solid var(--clr-fg);
  border-radius: 10px;
  transition: transform 0.2s linear;
  color: var(--clr-fg-alt);
}

.project-opendemia {
  background-color: rgb(26,36,65,0.25);
}

.project-crater {
  background-color: rgb(27,22,32,0.25);
}

.project-cat {
  background-color: rgb(242,228,254,0.25);
}

.project-nyc {
  background-color: rgb(0,137,124,0.25);
}

.project-mike {
  background-color: rgb(224,185,72,0.25);
}

.project-image {
  margin-top: 1em;
  height: 265px;
  border-radius: 10px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.opendemia {
  background-image: url('../../images/opendemia.jpg');
}

.crater {
  background-image: url('../../images/crater.jpg');
}

.cat {
  background-image: url('../../images/cat.jpg')
}

.nyc {
  background-image: url('../../images/nyc.jpg');
}

.mike {
  background-image: url('../../images/mike.jpg');
}

.project:hover {
  transform: translateY(-7px);
}

.project-title {
  background-color: rgb(0,0,0,0);
}

.project-description {
  padding-top: 0.5em;
  background-color: rgb(0,0,0,0);
}

.project-stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project-stack-item {
  margin: 0.5em;
  font-weight: 500; 
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}
